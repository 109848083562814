<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content}` : `University of Georgia`
    }}</template>
  </metainfo>
  <EmergencyBanner
    :heading="`UGA Alert Emergency Notification System`"
    :force="false"
    :messageOverride="
      `<strong>The University of Georgia will delay opening on Thursday, Jan. 23 until 10 a.m. The first in-person class for students will begin at 11:10 a.m.</strong>`
    "
    v-if="isHomepage"
    :class="isHomepage ? 'd-block' : 'd-none'"
  />

  <Navbar :transparent="isHomepage" />
  <router-view v-slot="{ Component, route }" :key="$route.query.q">
    <transition :name="route.meta.transition || 'fade'">
      <component :is="Component" />
    </transition>
  </router-view>
  <Footer v-show="!isLoading" />
</template>

<script>
import EmergencyBanner from "@/components/EmergencyBanner.vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  metaInfo() {
    // Set default meta
    return {
      title: "University of Georgia",
    };
  },
  data() {
    return {
      isLoading: true,
    };
  },
  components: {
    EmergencyBanner,
    Navbar,
    Footer,
  },
  computed: {
    isHomepage() {
      if (this.$route.path == "/" || this.$route.path == "/home") {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.loadingTimeout();
  },
  methods: {
    loadingTimeout() {
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss">
.fade {
  &-enter-active,
  &-leave-active {
    transition: opacity 300ms ease;
  }
  &-enter-from,
  &-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
</style>
